/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.page-sub-title{
  font-size: 18px;
}
.page-title{
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.header-btn-download{
  margin-top: 27px;
  float: right;
  margin-right: -23px;
}
.header-btn-row{
  float: right;
  // margin-top: 34px;
}
.pdf-btn{
  float: right;

}
.fas{
  font-size:14px;
  cursor: pointer;
}
.mdi{
  font-size:14px;
  cursor: pointer;
}
.table-footer {
  font-size: 14px;
}
.no-data-msg {
  font-size: 14px;
  padding-top: 2%;
  padding-bottom: 2%;
}
.btn-sm:focus:not(:focus-visible) {
  outline: 0!important;
  box-shadow: none!important;
  background-color: #556ee6!important;
  cursor: pointer;
}
.age-value {
  font-size: 10px;
  color: red;
  padding-top: 10px;
}
.select-style {
  font-size: 14px;
  line-height: 19px;
}

.image-holder {
  height: 90px;
  width: 100%;
  object-fit: contain;
}

.warning-tag {
  margin-top: 3px;
  font-size: 10px;
  color: red;
}